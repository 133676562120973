<template>
    <div class="cashierTradeDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: left"
                v-model="detail"
                size="small"
                label-width="85px"
            >
                <el-row style="margin-left: 0">
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input v-model="meta.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-input v-model="meta.staffName" style="width: 230px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="流水号">
                            <el-input placeholder="交易/退货" v-model="detail.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="detail.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="detail.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button size="small" type="primary" @click="handleQuery">查询</el-button>
            <el-button
                size="small"
                type="primary"
                @click="handleExport"
                v-if="hasPrivilege('menu.report.cashier.export')"
                >导出</el-button
            >
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="420"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="日期" width="200" prop="time" />
                <el-table-column label="交易流水号" width="300" prop="tradeNo" />
                <el-table-column label="退货流水号" width="300" prop="refundNo" />
                <el-table-column label="类型" width="160" prop="money" key="money">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="金额" width="160" prop="money" key="money">
                    <template slot-scope="scope">
                        <span>{{ scope.row.money | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="detail.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfStartDate from '../../../components/EfStartDate';
import EfEndDate from '../../../components/EfEndDate';

export default {
    name: 'CashierTradeDetail',
    props: ['form'],
    components: { EfEndDate, EfStartDate },
    data() {
        return {
            emptyZero: true,
            detail: {
                search: '',
                deptCode: this.form.deptCode || '',
                staffCode: this.form.staffCode || '',
                startTime: this.form.startTime || this.formatDate(new Date()),
                endTime: this.form.endTime || this.formatDate(new Date()),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/report/cash/cashierTradeAndRefundList',
                queryCashierList: '/report/cash/cashierList',
            },
            meta: {
                deptName: this.form.deptName || '',
                staffName: this.form.staffName || '',
            },
        };
    },
    filters: {
        type(type) {
            if (type == 1) {
                return '交易';
            } else {
                return '退货';
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        //代表从上一个页面返回的参数
        if (from.name == 'menu.report.cashier.detail.note') {
            const temForm = from.params.form.backForm || {};
            to.params.form = temForm;
        }
        next();
    },
    mounted() {
        const _this = this;
        _this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.detail };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            const _this = this;
            UrlUtils.Export(_this, '收银员交易退货列表', '/report/cash/export/cashierTradeAndRefundList', _this.detail);
        },
        rowDetail(row) {
            const _params = row;
            _params.backForm = this.form;
            _params.cashier = this.form.staffName;
            _params.deptName = this.form.deptName;
            Util.nameJump(this, 'menu.report.cashier.detail.note', ['报表管理', '收银交易汇总', '详情', '小票详情'], {
                form: _params,
            });
        },
    },
};
</script>

<style scoped>
.cashierTradeDetail .el-form-item {
    margin-bottom: 0;
}
</style>
